import React from 'react';
import { Elements } from 'prismic-richtext';
import linkResolver from 'utils/linkResolver';
import { Link as PrismicLink } from 'prismic-reactjs';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';

import PinkCircle from 'images/pink-circle.svg';

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
const htmlSerializer = function(type, element, content, children, key) {
  var props = {};
  switch (type) {
    case Elements.heading1: // Heading 1
      return (
        <Typography variant="h1" component="h1" gutterBottom key={key}>
          {children}
        </Typography>
      );

    case Elements.heading2: // Heading 2
      return (
        <Typography variant="h2" component="h2" gutterBottom key={key}>
          {children}
        </Typography>
      );

    case Elements.heading3: // Heading 3
      return (
        <Typography variant="h3" component="h3" gutterBottom key={key}>
          {children}
        </Typography>
      );

    case Elements.heading4: // Heading 4
      return (
        <Typography variant="h4" component="h4" gutterBottom key={key}>
          {children}
        </Typography>
      );

    case Elements.heading5: // Heading 5
      return React.createElement(
        'h5',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading6: // Heading 6
      return React.createElement(
        'h6',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.paragraph: // Paragraph
      return (
        <Typography variant="body1" component="p" gutterBottom key={key}>
          {children}
        </Typography>
      );

    case Elements.preformatted: // Preformatted
      return React.createElement(
        'pre',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.strong: // Strong
      return React.createElement(
        'strong',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.em: // Emphasis
      return React.createElement(
        'em',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.listItem: // Unordered List Item
      return React.createElement(
        'li',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.oListItem: // Ordered List Item
      return React.createElement(
        'li',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.list: // Unordered List
      return (
        <React.Fragment key={key}>
          {React.createElement('ul', { className: 'rich-text-list' }, children)}
        </React.Fragment>
      );

    case Elements.oList: // Ordered List
      return React.createElement(
        'ol',
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.image: {
      // Image
      const linkUrl = element.linkTo
        ? element.linkTo.url || linkResolver(element.linkTo)
        : null;
      const linkTarget =
        element.linkTo && element.linkTo.target
          ? { target: element.linkTo.target }
          : {};
      const linkRel = linkTarget.target ? { rel: 'noopener' } : {};
      const img = React.createElement('img', {
        src: element.url,
        alt: element.alt || '',
      });
      return React.createElement(
        'p',
        propsWithUniqueKey(
          { className: [element.label || '', 'block-img'].join(' ') },
          key
        ),
        linkUrl
          ? React.createElement(
              'a',
              Object.assign({ href: linkUrl }, linkTarget, linkRel),
              img
            )
          : img
      );
    }
    case Elements.embed: {
      // Embed
      props = Object.assign(
        {
          'data-oembed': element.oembed.embed_url,
          'data-oembed-type': element.oembed.type,
          'data-oembed-provider': element.oembed.provider_name,
        },
        element.label ? { className: element.label } : {}
      );
      const embedHtml = React.createElement('div', {
        dangerouslySetInnerHTML: { __html: element.oembed.html },
      });
      return React.createElement(
        'div',
        propsWithUniqueKey(props, key),
        embedHtml
      );
    }

    case Elements.hyperlink: {
      // Hyperlinks
      let result = '';
      const url = PrismicLink.url(element.data, linkResolver);
      if (element.data.link_type === 'Document') {
        result = (
          <Link to={url} key={key} className="RichTextBlocklink">
            {content}
          </Link>
        );
      } else {
        var targetAttr = element.data.target
          ? { target: element.data.target }
          : {};
        var relAttr = element.data.target ? { rel: 'noopener' } : {};
        var classAttr = { class: 'rich-text-hyperlink' };
        if (
          element.data.link_type == 'Media' ||
          element.data.link_type == 'Web'
        ) {
          targetAttr = { target: '_blank' };
          relAttr = { rel: 'noopener' };
        }
        props = Object.assign(
          {
            href: element.data.url || linkResolver(element.data),
          },
          targetAttr,
          relAttr,
          classAttr
        );
        result = React.createElement(
          'a',
          propsWithUniqueKey(props, key),
          children
        );
      }
      return result;
    }
    case Elements.label: {
      // Label
      props = element.data
        ? Object.assign({}, { className: element.data.label })
        : {};
      return React.createElement(
        'span',
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Elements.span: {
      // Span
      if (content) {
        return content.split('\\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = React.createElement(
              'br',
              propsWithUniqueKey({}, brIndex)
            );
            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }
    }
    default:
      // Always include a default that returns null
      return null;
  }
};

export default htmlSerializer;
